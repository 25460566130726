import type { MessageWithBody, SingleConversation } from "@grape-law-firm/grape-common-missive-client";
import { PhoneNumberAnalyzer } from "@grape-law-firm/grape-common-utils";
import type { MissiveGetConversationDetailsReqBody } from "~/server/api/missive/getConversationDetails.post";
import { AnalyticsEvent } from "~/types/analytics";
import type { MissiveContact, MissiveConversationMessage } from "~/types/missive";
import { isDevOrStaging } from "~/utils/dev";

export const useMissiveStore = defineStore("missive", () => {
  const { $posthog } = useNuxtApp();
  const toast = useToast();

  const client = computed(() => window.Missive);
  const active = computed(() => !!client.value?.id);
  const user = computed(() => {
    if (!client.value || !client.value.user) {
      if (isDevOrStaging()) {
        const { missiveDevEmail } = useRuntimeConfig().public;
        if (!missiveDevEmail) {
          throw createError({
            message: "You need to configure the environment variables correctly to access the app in development mode.",
            fatal: true,
          });
        }

        return {
          id: "dev",
          avatar: "",
          email: missiveDevEmail,
        };
      }
      return null;
    }

    return client.value.user;
  });

  // We fetch the conversations from Missive when the user clicks on a conversation.
  const selectedConversationIds = ref<string[]>([]);

  const selectedConversations = ref<Array<SingleConversation & {
    email_addresses: Array<{ address: string; name: string; }>;
    phone_numbers: Array<{ value: string; name: string; }>;
    messages: MessageWithBody[];
  }>>([]);

  const selectedConversationMessages = ref<MissiveConversationMessage[]>([]);

  const contactsInConversation = ref<MissiveContact[]>([]);

  watch(active, (active) => {
    if (!active)
      return;

    client.value.on("change:conversations", (ids: string[]) => {
      selectedConversationIds.value = ids;
      contactsInConversation.value = [];

      client.value.fetchConversations(ids).then((res) => {
        selectedConversations.value = res as any;
        for (const c of selectedConversations.value) {
          contactsInConversation.value.push(...c.email_addresses.map(e => ({ name: e.name ?? "", email: e.address })));
          contactsInConversation.value.push(...c.phone_numbers.map(e => ({ name: e.name ?? "", phoneNumber: e.value })));
        }
      });
    });
  }, { immediate: true });

  // Get more contacts from the conversation messages meta data and content
  const getFullConversationDetailsPending = ref(false);

  /**
   * This uses the REST API to fetch all the contacts from the meta fields of the full conversation and also the bodies of the messages.
   */
  async function getFullConversationDetails() {
    if (!selectedConversationIds.value.length)
      return;

    getFullConversationDetailsPending.value = true;

    try {
      const start = new Date();
      const res = await $fetch("/api/missive/getConversationDetails", {
        method: "POST",
        body: {
          conversationIds: selectedConversationIds.value,
        } satisfies MissiveGetConversationDetailsReqBody,
        retry: 3,
      });
      const end = new Date();
      $posthog.capture(AnalyticsEvent.MissiveContactsFetched, {
        duration: end.getTime() - start.getTime(),
      });

      // We only add new contacts
      for (const c of res.contacts) {
        if (contactsInConversation.value.some((f) => {
          if (f.email && c.email && f.email.trim() === c.email.trim())
            return true;

          if (f.phoneNumber && c.phoneNumber && f.phoneNumber.trim() === c.phoneNumber.trim())
            return true;

          return false;
        })) {
          continue;
        }

        contactsInConversation.value.push(c);
      }

      // We set the messages for future use
      selectedConversationMessages.value = res.bodies;
    }
    catch (error: any) {
      console.error(error);
      toast.add({
        icon: "i-heroicons-x-circle-solid",
        title: "Failed to fetch Missive contacts",
        description: error.message || "Please re-select the conversation and try again.",
        color: "red",
      });
    }

    getFullConversationDetailsPending.value = false;
  }

  // Filter contacts
  const externalContactsInConversation = computed(() => {
    const filtered: MissiveContact[] = [];
    if (!contactsInConversation.value?.length)
      return filtered;

    // We exclude internal contacts
    for (const c of contactsInConversation.value) {
      const excludedDomains = ["grapelaw.com", "hubspot.com"];
      if (c.email && excludedDomains.some(d => c.email?.includes(d)))
        continue;

      // Exclude our phone numbers
      const excludedNumbers = ["12124338383", "12124338484", "14403284848", "16466933993", "18444231444", "15513054838", "16468256556"];
      if (c.phoneNumber && excludedNumbers.some(n => n.includes(PhoneNumberAnalyzer.cleanNumber(c.phoneNumber!))))
        continue;

      // Sometimes our numbers appear in the name field
      if (c.name && excludedNumbers.some(n => n.includes(PhoneNumberAnalyzer.cleanNumber(c.name!))))
        continue;

      // Exclude our Instagram account
      const normalizedUsername = c.username?.normalize("NFD").replace(/[\u0300-\u036F]/g, "").toLowerCase().trim();
      const normalizedName = c.name?.normalize("NFD").replace(/[\u0300-\u036F]/g, "").toLowerCase().trim();
      if ([normalizedName, normalizedUsername].includes("avukat muhammed uzum") || normalizedUsername === "uzumesq")
        continue;

      filtered.push(c);
    }

    return filtered;
  });

  return {
    active,
    client,
    user,
    selectedConversationIds,
    selectedConversations,
    selectedConversationMessages,
    contactsInConversation,
    externalContactsInConversation,
    getFullConversationDetails,
    getFullConversationDetailsPending,
  };
});
